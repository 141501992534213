import React from 'react';
import { useTranslation } from 'react-i18next';
import './index-home-section.css'

import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@/components/ui/carousel"

import Autoplay from "embla-carousel-autoplay"


function IndexHomeSection() {

    const { t } = useTranslation(['index-home-section']);

    return <Carousel className="h-screen flex flex-row text-right w-full" id="home"
        opts={{
            align: "start",
            loop: true,
        }}

        //plugins={[
        //    Autoplay({
        //        delay: 5000,
        //    }),
        //]}
        >
        <CarouselContent>
            <CarouselItem className="pt-0 pr-[10vw] pb-32 pl-36 xl:pb-44 xl:pl-48  background_1 justify-start flex   ">
                <div className='space-y-4 xl:w-3/4 w-4/5 text-start items-center flex'>
                    <div className='xl:space-y-5 space-y-7'>
                        <h1 className="text-white text-5xl xl:text-6xl font-bold">{t('block01.title')}</h1>
                        <p className="xl:text-5xl text-4xl leading-9l p-2 text-white mb-4 xl:pt-10 ">
                            {t('block02.text')}
                        </p>
                        <a href="#contact_us">
                            <button className="p-5 mt-10 xl:w-2/4 xl:text-5xl w-2/4 text-3xl" type="button">
                                {t('block01.button')}
                            </button>
                        </a>
                    </div>
                </div>
            </CarouselItem>
            <CarouselItem className="pl-4 ">
                <div className='flex flex-col items-center justify-center text-center w-3/4 mx-auto background_2 pt-10'>
                    <h1 className='text-5xl xl:text-6xl font-bold text-teal-800'>
                       {t('block02.title')}
                    </h1>
                    <p className="xl:text-5xl text-4xl leading-9 p-2 text-teal-800 mb-4 xl:pt-10 pt-5">
                    {t('block02.text')}                    </p>
                    <a href="#contact_us">
                        <button className="p-5 mt-4 w-full text-4xl bg-teal-800 border-teal-800 text-white hover:text-teal-800" type="button">
                            {t('block02.button')}
                        </button>
                    </a>
                </div>
            </CarouselItem>
            <CarouselItem className="pl-4 background_3 flex flex-col items-end justify-center  pr-10  ">
                <div className='flex flex-col items-end justify-center  pr-10 w-4/4 space-y-6 '>
                    <h2 className='text-3xl xl:text-5xl font-bold text-[#06dd9d]'>
                    {t('block03.title')}
                    </h2>
                    <h1 className='text-5xl xl:text-7xl font-semibold text-white mt-2 w-2/4'>
                    {t('block03.text')}                    </h1>
                    <a href="#contact_us">
                        <button className="p-5 mt-4 w-full text-4xl " type="button">
                        {t('block03.button')}
                        </button>
                    </a>
                </div>

            </CarouselItem>
        </CarouselContent>
        <CarouselPrevious className="md:ml-[100px] xl:ml-[160px] text-black h-12 w-12 hover:text-green-400" />
        <CarouselNext className="md:mr-[100px] xl:mr-[160px] text-black h-12 w-12 hover:text-green-400" />
    </Carousel>
}

export default IndexHomeSection;