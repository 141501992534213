import React from 'react';
import { useTranslation } from 'react-i18next';

import FagronTechLogo from '../../img/fagron_color.svg'
import SantillanaLogo from '../../img/santillana_logo.png'
import HavanLogo from '../../img/havan_logo.png'

function IndexClientsSection() {

    const { t } = useTranslation(['index-clients-section']);

    return <article className="index-clients-section h-[75%] flex justify-center items-center bg-[#f5f5f5] p-24 w-full" id="clients">
        <section >
            <p className="text-lg text-center font-medium text-stone-500 mb-6">{t('title')}</p>

            <div className="grid grid-cols-1  md:flex md:flex-wrap gap-16 items-center">
                <img className='h-12 mr-4 xl:h-16' src={FagronTechLogo} />
                <img className='h-16 mr-4 xl:h-24' src={SantillanaLogo} />
                <img className='h-16 mr-4 xl:h-24' src={HavanLogo} />
            </div>


        </section>
    </article>
}

export default IndexClientsSection 