import * as React from 'react';
import Helmet from 'react-helmet';
import '../css/global.less';
import { useTranslation } from 'react-i18next';


import logo from '../img/KXP_logo_White.svg';



import '../i18n';
import Footer from '../components/Footer';
import MainMenu from '../components/layout/menu/main-menu';
import IndexHomeSection from '../components/page-sections/index-home-section';
import IndexAboutUsSection from '../components/page-sections/index-aboutus-section';
import IndexServiceSection from '../components/page-sections/index-services-section';

import IndexClientsSection from '../components/page-sections/index-clients.section'
import AboutUsPage from './about_us/about-us';
import BlogSection from '../components/page-sections/blog-section';
import ContactPage from './contact_us';


const lngs = {
  en: { nativeName: 'enUs', id: 1 },
  pt: { nativeName: 'ptBr', id: 2 },
};

// markup

function IndexPage() {
  const { t, i18n } = useTranslation(['namespace_test']);
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };
  return (
    <>
      <Helmet>
        <title>KXP – Technology & Consulting</title>
        <meta name="description" content={t('description')} />
      </Helmet>
      <link rel="icon" href="../img/favicon.ico" />
      <header>
        <a href="#home"><img className="logo" src={logo} alt="KXP technology consulting" /></a>
        <MainMenu />
      </header>
      <main>
        <IndexHomeSection />
        <IndexServiceSection />
        <IndexAboutUsSection />
        {/*    <IndexDevPlatformSection />
        <IndexCustomProjectsSection /> }
        <IndexPartnersSection /> */}
        <IndexClientsSection />
        <BlogSection/>
        <AboutUsPage/>
        <ContactPage/>
        { /*<Contact /> */}
      </main>

      <Footer />
    </>
  );
}
export default IndexPage;
