import React from 'react';
import { useTranslation } from 'react-i18next';


import "./index-aboutus-section.css";
import { Link } from 'gatsby';

function IndexAboutUsSection() {

    const { t } = useTranslation(['index-aboutus-section']);
    return <article className="index-aboutus-section h-screen flex items-center w-full" id="about_us">
        <section >
            <div className=' space-y-4 p-10 max-w-2xl xl:max-w-4xl'>
                <h1 className="font-bold text-4xl">
                    {t('title')}
                </h1>
                <p className='accent-color text-3xl font-semibold'>{t('subtitle')}</p>
                <div className="leading-5">
                    <div>
                        <p className='text-lg'>
                            {t('text01')}
                        </p>
                    </div>
                </div>
                <div className="leading-5">
                    <div>
                        <p className='text-lg'>
                            {t('text02')}
                        </p>
                    </div>
                </div>
                <div className=''>
                    <Link to="/#about_us_section">
                        <button>{t('button')}</button>
                    </Link>
                </div>
            </div>
        </section>
    </article>
}

export default IndexAboutUsSection;