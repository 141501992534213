import * as React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { useTranslation } from 'react-i18next';





function BlogSection() {

  const { t } = useTranslation('index-blog-section')

  const query = useStaticQuery(graphql`
        {
          allWpPost {
            totalCount
            nodes {
              title
              id
              slug
              link
              excerpt
              internal{
                description
              }
              featuredImage {
                node {
                  mediaItemUrl
                }
              }
            }
          }
        }
      `);

  const hasImage = (node) => {
    if (node.featuredImage) {
      return true;
    }
    return false;
  };

  const siteUrl = 'https://app-kxp-www-prod-02.azurewebsites.net/';

  const cleanParagraphTag = (text) => {
    const cleanText = text.replace(/<p>/g, '');
    const limitText = cleanText.substring(0, 200);
    return limitText.replace(/<\/p>/g, '');
  };

  const formatData = query.allWpPost.nodes.slice(1, 4)


  return <article className="w-full md:h-screen flex justify-center p-10 bg-[#faf8f6]" id="blog">
    <div className='flex flex-col items-center justify-center '>
      <h1 className='accent-color text-xl font-bold  '>
{t('title')}      </h1>
      <div className="grid grid-row-3 md:flex  m-10 gap-5 h-full">
        {formatData.map((post) => (
          <div className="w-45vh h-50vh bg-cover bg-center bg-no-repeat m-0 mr-1rem mb-0.5rem" key={post.id} style={hasImage(post) ? { backgroundImage: `url(${siteUrl}${post.featuredImage.node.mediaItemUrl})` } : {}}>
            {/* <Link to={post.slug}>{post.title}</Link> */}
            <div className="w-full h-full pt-6 pr-10 pb-4 pl-5 opacity-0 bg-[#1C2B33ef] transition-all duration-500 hover:opacity-100 leading-6 mt-2 mb-4 space-y-2">
              <h3 className="accent-color font-bold">{post.title}</h3>
              <p className="white text-base">{cleanParagraphTag(post.excerpt)}</p>
              <div className='mt-4'>
                <Link to={`/blog/${post.slug}`} className="border-solid border-2 border-white rounded-sm text-white text-sm font-bold text-center w-1/2 p-2 ">{t('readMore')}</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='mb-10 w-full justify-start ml-20'>

        <a href="/blog">
          <button className='mt-2'>
{t('button')}          </button>
        </a>
      </div>
    </div>
  </article>
}

export default BlogSection